import React from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"
import Typography from "@material-ui/core/Typography"

export default class Privacy extends React.Component {

    render() {
        return (
           <Layout>
               <SEO title="Twitch Countdown Overlay Configurator"/>
               <Typography component="h1">Privacy Policy</Typography>
               <br/>
               <br/>
               <Typography component="h2"><strong>1. An overview of data protection</strong></Typography>
               <br/>
               <Typography component="h3">General information</Typography>
               <Typography variant="body2" component="p">
                   The following information will provide you with an easy to navigate overview of what will happen with
                   your personal data when you visit this website. The term “personal data” comprises all data that can
                   be used to personally identify you. For detailed information about the subject matter of data
                   protection, please consult our Data Protection Declaration, which we have included beneath this copy.
               </Typography>
               <br/>
               <Typography component="h3">Data recording on this website</Typography>
               <br/>
               <Typography variant="body2" component="p">
                   <strong>Who is the responsible party for the recording of data on this website (i.e. the
                       “controller”)?</strong>
                   <br/>
                   The data on this website is processed by the operator of the
                   website, whose contact information is available under section “Information Required by Law” on this
                   website.
                   <br/><br/>
                   <strong>How do we record your data?</strong>
                   <br/>
                   We collect your data as a result of
                   your sharing of your data with us. This may, for instance be information you enter into our contact
                   form.
                   <br/><br/>
                   Our IT systems automatically record other data when you visit our website. This data
                   comprises primarily technical information (e.g. web browser, operating system or time the site was
                   accessed). This information is recorded automatically when you access this website.
                   <br/><br/>
                   <strong>What
                       are the purposes we use your data for?</strong>
                   <br/>
                   A portion of the information is generated to
                   guarantee the error free provision of the website. Other data may be used to analyse your user
                   patterns.
                   <br/><br/>
                   <strong>What rights do you have as far as your information is concerned?</strong>
                   <br/>
                   You have the right to receive information about the source, recipients and purposes of your archived
                   personal data at any time without having to pay a fee for such disclosures. You also have the right
                   to demand that your data are rectified or eradicated. Please do not hesitate to contact us at any
                   time under the address disclosed in section “Information Required by Law” on this website if you have
                   questions about this or any other data protection related issues. You also have the right to log a
                   complaint with the competent supervising agency.
                   <br/><br/>
                   Moreover, under certain circumstances, you
                   have the right to demand the restriction of the processing of your personal data. For details, please
                   consult the Data Protection Declaration under section “Right to Restriction of Data Processing.”
               </Typography>
               <br/>
               <Typography component="h3">Analysis tools and tools provided by third parties</Typography>
               <br/>
               <Typography variant="body2" component="p">
                   There is a possibility that your browsing patterns will be statistically analysed when your visit
                   this website. Such analyses are performed primarily with cookies and with what we refer to as
                   analysis programmes. As a rule, the analyses of your browsing patterns are conducted anonymously;
                   i.e. the browsing patterns cannot be traced back to you.
                   <br/>
                   You have the option to object to such
                   analyses or you can prevent their performance by not using certain tools. For detailed information
                   about
                   the tools and about your options to object, please consult our Data Protection Declaration below.
               </Typography>

               <br/>
               <br/>
               <Typography component="h2"><strong>2. Hosting and Content Delivery Networks (CDN)</strong></Typography>
               <br/>
               <Typography variant="body2" component="p">
                   This website is hosted by an external service provider (host). Personal data collected on this
                   website are stored on the servers of the host. These may include, but are not limited to, IP
                   addresses, contact requests, metadata and communications, contract information, contact information,
                   names, web page access, and other data generated through a web site.
                   <br/><br/>
                   The host is used for
                   the
                   purpose of fulfilling the contract with our potential and existing customers (Art. 6 para. 1 lit. b
                   DSGVO) and in the interest of secure, fast and efficient provision of our online services by a
                   professional provider (Art. 6 para. 1 lit. f DSGVO).
                   <br/><br/>
                   Our host will only process your data to
                   the
                   extent necessary to fulfil its performance obligations and to follow our instructions with respect to
                   such data.
                   <br/><br/>
                   <strong>Execution of a contract data processing agreement</strong>
                   <br/><br/>
                   In order to guarantee processing in compliance with data protection regulations, we have concluded an
                   order processing contract with our host.
               </Typography>

               <br/>
               <br/>
               <Typography component="h2"><strong>3. General information and mandatory information</strong></Typography>
               <br/>
               <Typography component="h3">Data protection</Typography>
               <br/>
               <Typography variant="body2" component="p">
                   The operators of this website and its pages take the protection of your personal data very seriously.
                   Hence, we handle your personal data as confidential information and in compliance with the statutory
                   data protection regulations and this Data Protection Declaration.
                   <br/>
                   <br/>
                   Whenever you use this
                   website, a variety of personal information will be collected. Personal data comprises data that can
                   be
                   used to personally identify you. This Data Protection Declaration explains which data we collect as
                   well
                   as the purposes we use this data for. It also explains how, and for which purpose the information is
                   collected.
                   <br/>
                   <br/>
                   We herewith advise you that the transmission of data via the Internet (i.e. through
                   e-mail communications) may be prone to security gaps. It is not possible to completely protect data
                   against third party access.
               </Typography>


               <br/>
               <Typography component="h3">Information about the responsible party (referred to as the “controller” in
                   the GDPR)</Typography>
               <br/>
               <Typography variant="body2" component="p">
                   The data processing controller on this website is:
                   <br/><br/>
                   Marco Kernler<br/>
                   Raiffeisenweg 1<br/>
                   72505 Krauchenwies
                   <br/>
                   Phone: +49 7576 962 643<br/>
                   E-mail:  info@twitch-countdown.com
                   <br/><br/>
                   The controller is the natural person or legal entity that single-handedly or jointly with others
                   makes decisions as to the purposes of and resources for the processing of personal data (e.g. names,
                   e-mail addresses, etc.).
               </Typography>


               <br/>
               <Typography component="h3">Revocation of your consent to the processing of data</Typography>
               <br/>
               <Typography variant="body2" component="p">
                   A wide range of data processing transactions are possible only subject to your express consent. You
                   can also revoke at any time any consent you have already given us. To do so, all you are required to
                   do is sent us an informal notification via e-mail. This shall be without prejudice to the lawfulness
                   of any data collection that occurred prior to your revocation.
               </Typography>

               <br/>
               <Typography component="h3">Right to object to the collection of data in special cases; right to object to
                   direct advertising (Art. 21 GDPR)</Typography>
               <br/>
               <Typography variant="body2" component="p">
                   IN THE EVENT THAT DATA ARE PROCESSED ON THE BASIS OF ART. 6 SECT. 1 LIT. E OR F GDPR, YOU HAVE THE
                   RIGHT TO AT ANY TIME OBJECT TO THE PROCESSING OF YOUR PERSONAL DATA BASED ON GROUNDS ARISING FROM
                   YOUR UNIQUE SITUATION. THIS ALSO APPLIES TO ANY PROFILING BASED ON THESE PROVISIONS. TO DETERMINE THE
                   LEGAL BASIS, ON WHICH ANY PROCESSING OF DATA IS BASED, PLEASE CONSULT THIS DATA PROTECTION
                   DECLARATION. IF YOU LOG AN OBJECTION, WE WILL NO LONGER PROCESS YOUR AFFECTED PERSONAL DATA, UNLESS
                   WE ARE IN A POSITION TO PRESENT COMPELLING PROTECTION WORTHY GROUNDS FOR THE PROCESSING OF YOUR DATA,
                   THAT OUTWEIGH YOUR INTERESTS, RIGHTS AND FREEDOMS OR IF THE PURPOSE OF THE PROCESSING IS THE
                   CLAIMING, EXERCISING OR DEFENCE OF LEGAL ENTITLEMENTS (OBJECTION PURSUANT TO ART. 21 SECT. 1
                   GDPR).
                   <br/><br/>
                   IF YOUR PERSONAL DATA IS BEING PROCESSED IN ORDER TO ENGAGE IN DIRECT ADVERTISING, YOU
                   HAVE THE RIGHT TO AT ANY TIME OBJECT TO THE PROCESSING OF YOUR AFFECTED PERSONAL DATA FOR THE
                   PURPOSES OF
                   SUCH ADVERTISING. THIS ALSO APPLIES TO PROFILING TO THE EXTENT THAT IT IS AFFILIATED WITH SUCH DIRECT
                   ADVERTISING. IF YOU OBJECT, YOUR PERSONAL DATA WILL SUBSEQUENTLY NO LONGER BE USED FOR DIRECT
                   ADVERTISING
                   PURPOSES (OBJECTION PURSUANT TO ART. 21 SECT. 2 GDPR).
               </Typography>

               <br/>
               <Typography component="h3">Right to log a complaint with the competent supervisory agency</Typography>
               <br/>
               <Typography variant="body2" component="p">
                   In the event of violations of the GDPR, data subjects are entitled to log a complaint with a
                   supervisory agency, in particular in the member state where they usually maintain their domicile,
                   place of work or at the place where the alleged violation occurred. The right to log a complaint is
                   in effect regardless of any other administrative or court proceedings available as legal recourses.
               </Typography>

               <br/>
               <Typography component="h3">Right to data portability</Typography>
               <br/>
               <Typography variant="body2" component="p">
                   You have the right to demand that we hand over any data we automatically process on the basis of your
                   consent or in order to fulfil a contract be handed over to you or a third party in a commonly used,
                   machine readable format. If you should demand the direct transfer of the data to another controller,
                   this will be done only if it is technically feasible.
               </Typography>


               <br/>
               <Typography component="h3">SSL and/or TLS encryption</Typography>
               <br/>
               <Typography variant="body2" component="p">
                   For security reasons and to protect the transmission of confidential content, such as purchase orders
                   or inquiries you submit to us as the website operator, this website uses either an SSL or a TLS
                   encryption programme. You can recognise an encrypted connection by checking whether the address line
                   of the browser switches from “http://” to “https://” and also by the appearance of the lock icon in
                   the browser line.
                   <br/><br/>
                   If the SSL or TLS encryption is activated, data you transmit to us cannot be
                   read by third parties.
               </Typography>


               <br/>
               <Typography component="h3">Information about, rectification and eradication of data</Typography>
               <br/>
               <Typography variant="body2" component="p">
                   Within the scope of the applicable statutory provisions, you have the right to at any time demand
                   information about your archived personal data, their source and recipients as well as the purpose of
                   the processing of your data. You may also have a right to have your data rectified or eradicated. If
                   you have questions about this subject matter or any other questions about personal data, please do
                   not hesitate to contact us at any time at the address provided in section “Information Required by
                   Law.”
               </Typography>


               <br/>
               <Typography component="h3">Right to demand processing restrictions</Typography>
               <br/>
               <Typography variant="body2" component="p">
                   You have the right to demand the imposition of restrictions as far as the processing of your personal
                   data is concerned. To do so, you may contact us at any time at the address provided in section
                   “Information Required by Law.” The right to demand restriction of processing applies in the following
                   cases:
                   <br/>
                   <br/>
                </Typography>
                <ul>
                    <li>In the event that you should dispute the correctness of your data archived by us, we will
                        usually
                        need some time to verify this claim. During the time that this investigation is ongoing, you
                        have
                        the right to demand that we restrict the processing of your personal data.
                    </li>
                    <li>If the processing of your personal data was/is conducted in an unlawful manner, you have the
                        option to demand the restriction of the processing of your data in lieu of demanding the
                        eradication of this data.
                    </li>
                    <li>If we do not need your personal data any longer and you need it to exercise, defend or claim
                        legal entitlements, you have the right to demand the restriction of the processing of your
                        personal data instead of its eradication.
                    </li>
                    <li>If you have raised an objection pursuant to Art. 21 Sect. 1 GDPR, your rights and our rights
                        will
                        have to be weighed against each other. As long as it has not been determined whose interests
                        prevail, you have the right to demand a restriction of the processing of your personal data.
                    </li>
                </ul>
                <br/>
                <Typography variant="body2" component="p">
                   If you have restricted the processing of your personal data, these data – with the exception of their
                   archiving – may be processed only subject to your consent or to claim, exercise or defend legal
                   entitlements or to protect the rights of other natural persons or legal entities or for important
                   public interest reasons cited by the European Union or a member state of the EU.
               </Typography>


               <br/>
               <Typography component="h3">Rejection of unsolicited e-mails</Typography>
               <br/>
               <Typography variant="body2" component="p">
                   We herewith object to the use of contact information published in conjunction with the mandatory
                   information to be provided in section “Information Required by Law” to send us promotional and
                   information material that we have not expressly requested. The operators of this website and its
                   pages reserve the express right to take legal action in the event of the unsolicited sending of
                   promotional information, for instance via SPAM messages.
               </Typography>

               <br/>
               <br/>
               <Typography component="h2"><strong>4. Recording of data on this website</strong></Typography>
               <br/>
               <Typography component="h3">Request by e-mail, telephone or fax</Typography>
               <br/>
               <Typography variant="body2" component="p">
                   If you contact us by e-mail, telephone or fax, your request, including all resulting personal data
                   (name, request) will be stored and processed by us for the purpose of processing your request. We do
                   not pass these data on without your consent.
                   <br/>
                   <br/>
                   The processing of these data is based on Art. 6
                   para. 1 lit. b GDPR, if your request is related to the execution of a contract or if it is necessary
                   to
                   carry out pre-contractual measures. In all other cases, the processing is based on your consent
                   (Article
                   6 (1) a GDPR) and/or on our legitimate interests (Article 6 (1) (f) GDPR), since we have a legitimate
                   interest in the effective processing of requests addressed to us.
                   <br/><br/>
                   The data sent by you to us via
                   contact requests remain with us until you request us to delete, revoke your consent to the storage or
                   the
                   purpose for the data storage lapses (e.g. after completion of your request). Mandatory statutory
                   provisions - in particular statutory retention periods - remain unaffected.
               </Typography>

               <br/>
               <br/>
               <Typography component="h2"><strong>5. Analysis tools and advertising</strong></Typography>
               <br/>
               <Typography component="h3">Google Analytics</Typography>
               <br/>
               <Typography variant="body2" component="p">
                   This website uses functions of the web analysis service Google Analytics. The provider of this
                   service is Google Ireland Limited (“Google”), Gordon House, Barrow Street, Dublin 4, Ireland.
                   <br/><br/>
                   Google Analytics uses so-called cookies. Cookies are text files, which are stored on your computer
                   and
                   that enable an analysis of the use of the website by users. The information generated by cookies on
                   your use of this website is usually transferred to a Google server in the United States, where it is
                   stored.
                   <br/><br/>
                   The storage of Google Analytics cookies and the utilization of this analysis tool are
                   based on Art. 6 Sect. 1 lit. f GDPR. The operator of this website has a legitimate interest in the
                   analysis of user patterns to optimize both, the services offered online and the operator’s
                   advertising
                   activities. If a corresponding agreement has been requested (e.g. an agreement to the storage of
                   cookies), the processing takes place exclusively on the basis of Art. 6 para. 1 lit. a GDPR; the
                   agreement can be revoked at any time.
                   <br/><br/>
                   <strong>IP anonymization</strong>
                   <br/><br/>
                   On this website, we
                   have activated the IP anonymization function. As a result, your IP address will be abbreviated by
                   Google
                   within the member states of the European Union or in other states that have ratified the Convention
                   on
                   the European Economic Area prior to its transmission to the United States. The full IP address will
                   be
                   transmitted to one of Google’s servers in the United States and abbreviated there only in exceptional
                   cases. On behalf of the operator of this website, Google shall use this information to analyse your
                   use
                   of this website to generate reports on website activities and to render other services to the
                   operator of
                   this website that are related to the use of the website and the Internet. The IP address transmitted
                   in
                   conjunction with Google Analytics from your browser shall not be merged with other data in Google’s
                   possession.
                   <br/><br/>
                   <strong>Browser plug-in</strong>
                   <br/><br/>
                   You do have the option to prevent the archiving of cookies by
                   making pertinent changes to the settings of your browser software. However, we have to point out that
                   in
                   this case you may not be able to use all of the functions of this website to their fullest extent.
                   Moreover, you have the option prevent the recording of the data generated by the cookie and
                   affiliated
                   with your use of the website (including your IP address) by Google as well as the processing of this
                   data
                   by Google by downloading and installing the browser plug-in available under the following link:
                   <a href="https://tools.google.com/dlpage/gaoptout?hl=en" target="_blank"
                  rel="noopener noreferrer">https://tools.google.com/dlpage/gaoptout?hl=en</a>.
                   <br/><br/>
                   <strong>Objection
                       to the recording of data</strong>
                   <br/><br/>
                   You have the option to prevent the recording of your data by
                   Google Analytics by clicking on the following link. This will result in the placement of an opt out
                   cookie, which prevents the recording of your data during future visits to this website:
                   <br/><br/>
                   <a href="#gaOutOut" onClick={e => window["gaOptout"]()}>Google Analytics deactivation</a>
                   <br/><br/>
                   For more information about the
                   handling of user data by Google Analytics, please consult Google’s Data Privacy Declaration at:
                   <a href="https://support.google.com/analytics/answer/6004245?hl=en" target="_blank" rel="noopener noreferrer">https://support.google.com/analytics/answer/6004245?hl=en</a>.
                   <br/><br/>
                   <strong>Contract data processing</strong>
                   <br/><br/>
                   We have executed a contract data processing agreement
                   with Google and are implementing the stringent provisions of the German data protection agencies to
                   the
                   fullest when using Google Analytics.
                   <br/><br/>
                   <strong>Demographic parameters provided by Google Analytics</strong>
                   <br/><br/>
                   This website uses the
                   function “demographic parameters” provided by Google Analytics. It makes it possible to generate
                   reports
                   providing information on the age, gender and interests of website visitors. The sources of this
                   information are interest-related advertising by Google as well as visitor data obtained from third
                   party
                   providers. This data cannot be allocated to a specific individual. You have the option to deactivate
                   this
                   function at any time by making pertinent settings changes for advertising in your Google account or
                   you
                   can generally prohibit the recording of your data by Google Analytics as explained in section
                   “Objection
                   to the recording of data”.
                   <br/><br/>
                   <strong>Archiving period</strong>
                   <br/><br/>
                   Data on the user or incident level stored by Google linked to
                   cookies, user IDs or advertising IDs (e.g. DoubleClick cookies, Android advertising ID) will be
                   anonymized or deleted after 14 month. For details please click the following link: <a
                  href="https://support.google.com/analytics/answer/7667196?hl=en" target="_blank"
                  rel="noopener noreferrer">https://support.google.com/analytics/answer/7667196?hl=en</a>
               </Typography>


               <br/>
               <Typography component="h3">Google AdSense</Typography>
               <br/>
               <Typography variant="body2" component="p">
                   This website uses Google AdSense, a service for the integration of ads. The provider of this service
                   is Google Ireland Limited (“Google”), Gordon House, Barrow Street, Dublin 4, Ireland.
                   <br/><br/>
                   Google AdSense uses so-called “cookies”; i.e. text files that are stored on your computer and that
                   make it possible to analyse your use of this website. Google AdSense also uses so-called Web Beacons
                   (invisible graphics). These Web Beacons can be used to analyse information, such as visitor traffic
                   data, on this website.
                   <br/><br/>
                   The information generated by the cookies and the Web Beacons concerning
                   the use of this website (including your IP address) and the delivery of advertising formats are
                   transferred to a Google server in the United States, where they are archived. Google may share this
                   information with Google contracting partners. Google shall, however, not merge your IP address with
                   any
                   other of your data stored by Google.
                   <br/><br/>
                   The storage of the AdSense cookies is based on Art. 6 Sect. 1
                   lit. f GDPR. The website operator has a legitimate interest in the analysis of user patterns, in
                   order to
                   optimize the operator’s web offerings and advertising.
                   <br/><br/>
                   You can prevent the installation of these
                   cookies by making a pertinent adjustment to your browser software settings; however, we have to point
                   out
                   that in this case you may not be able to use all of the functions of this website to their fullest
                   extent. By using this website, you declare your consent to the processing of the data we collect
                   about
                   you by Google in the manner described above and agree to the aforementioned purpose of use.
               </Typography>


               <br/>
               <Typography component="h3">Google Analytics Remarketing</Typography>
               <br/>
               <Typography variant="body2" component="p">
                   This website uses the functions of Google Analytics Remarketing in combination with the functions of
                   Google Ads and Google DoubleClick, which work on all devices. The provider of these solutions is
                   Google Ireland Limited (“Google”), Gordon House, Barrow Street, Dublin 4, Ireland.
                   <br/><br/>
                   This function makes it possible to connect the advertising target groups generated with Google
                   Analytics Remarketing with the functions of Google Ads and Google DoubleClick, which work on all
                   devices. This makes it possible to display interest-based customized advertising messages, depending
                   on your prior usage and browsing patterns on a device (e.g. cell phone) in a manner tailored to you
                   as well as on any of your devices (e.g. tablet or PC).
                   <br/><br/>
                   If you have given us pertinent consent, Google will connect your web and app browser progressions
                   with your Google account for this purpose. As a result, it is possible to display the same
                   personalized advertising messages on every device you log into with your Google account.
                   <br/><br/>
                   To support this function, Google Analytics records Google authenticated IDs of users that are
                   temporarily connected with our Google Analytics data to define and compile the target groups for the
                   ads to be displayed on all devices.
                   <br/><br/>
                   You have the option to permanently object to remarketing/targeting across all devices by deactivating
                   personalized advertising. To do this, please follow this link: <a
                  href="https://www.google.com/settings/ads/onweb/" target="_blank"
                  rel="noopener noreferrer">https://www.google.com/settings/ads/onweb/</a>.
                   <br/><br/>
                   The consolidation of the recorded data in your Google account shall occur exclusively based on your
                   consent, which you may give to Google and also revoke there (Art. 6 Sect. 1 lit. a GDPR). Data
                   recording processes that are not consolidated in your Google account (for instance because you do not
                   have a Google account or have objected to the consolidation of data), the recording of data is based
                   on Art. 6 Sect. 1 lit. f GDPR. The legitimate interest arises from the fact that the operator of the
                   website has a legitimate interest in the anonymized analysis of website visitor for advertising
                   purposes.
                   <br/><br/>
                   For further information and the pertinent data protection regulations, please consult the Data
                   Privacy Policies of Google at: <a href="https://policies.google.com/technologies/ads?hl=en"
                                                     target="_blank"
                                                     rel="noopener noreferrer">https://policies.google.com/technologies/ads?hl=en</a>.
               </Typography>


               <br/>
               <Typography component="h3">Google Ads and Google Conversion Tracking</Typography>
               <br/>
               <Typography variant="body2" component="p">
                   This website uses Google Ads. Google Ads is an online promotional program of Google Ireland Limited
                   (“Google”), Gordon House, Barrow Street, Dublin 4, Ireland.
                   <br/><br/>
                   In conjunction with Google Ads, we use a tool called Conversion Tracking. If you click on an ad
                   posted by Google, a cookie for Conversion Tracking purposes will be placed. Cookies are small text
                   files the web browser places on the user’s computer. These cookies expire after 30 days and are not
                   used to personally identify users. If the user visits certain pages of this website and the cookie
                   has not yet expired, Google and we will be able to recognise that the user has clicked on an ad and
                   has been linked to this page.
                   <br/><br/>
                   A different cookie is allocated to every Google Ads customer. These cookies cannot be tracked via
                   websites of Google Ads customers. The information obtained with the assistance of the Conversion
                   cookie is used to generate Conversion statistics for Google Ads customers who have opted to use
                   Conversion Tracking. The users receive the total number of users that have clicked on their ads and
                   have been linked to a page equipped with a Conversion Tracking tag. However, they do not receive any
                   information that would allow them to personally identify these users. If you do not want to
                   participate in tracking, you have the option to object to this use by easily deactivating the Google
                   Conversion Tracking cookie via your web browser under user settings. If you do this, you will not be
                   included in the Conversion Tracking statistics.
                   <br/><br/>
                   The storage of “Conversion” cookies and the use of this tracking tool are based on Art. 6 Sect. 1
                   lit. f GDPR. The website operator has a legitimate interest in the analysis of user patterns, in
                   order to optimize the operator’s web offerings and advertising. If a corresponding agreement has been
                   requested (e.g. an agreement to the storage of cookies), the processing takes place exclusively on
                   the basis of Art. 6 para. 1 lit. a GDPR; the agreement can be revoked at any time.
                   <br/><br/>
                   To review more detailed information about Google Ads and Google Conversion Tracking, please consult
                   the Data Privacy Policies of Google at: <a href="https://policies.google.com/privacy?hl=en"
                                                              target="_blank"
                                                              rel="noopener noreferrer">https://policies.google.com/privacy?hl=en</a>.
                   <br/><br/>
                   You can set up your browser in such a manner that you will be notified anytime cookies are placed and
                   you can permit cookies only in certain cases or exclude the acceptance of cookies in certain
                   instances or in general and you can also activate the automatic deletion of cookies upon closing of
                   the browser. If you deactivate cookies, the functions of this website may be limited.
               </Typography>

               <br/>
               <br/>
               <Typography component="h2"><strong>6. Plug-ins and Tools</strong></Typography>
               <br/>
               <Typography component="h3">Google Web Fonts</Typography>
               <br/>
               <Typography variant="body2" component="p">
                   To ensure that fonts used on this website are uniform, this website uses so-called Web Fonts provided
                   by Google. When you access a page on our website, your browser will load the required web fonts into
                   your browser cache to correctly display text and fonts.
                   <br/><br/>
                   To do this, the browser you use will
                   have to establish a connection with Google’s servers. As a result, Google will learn that your IP
                   address
                   was used to access this website. The use of Google Web Fonts is based on Art. 6 Sect. 1 lit. f GDPR.
                   The
                   website operator has a legitimate interest in a uniform presentation of the font on the operator’s
                   website. If a respective declaration of consent has been obtained (e.g. consent to the archiving of
                   cookies), the data will be processed exclusively on the basis of Art. 6 Sect. 1 lit. a DGDPR. Any
                   such
                   consent may be revoked at any time.
                   <br/><br/>
                   If your browser should not support Web Fonts, a standard font
                   installed on your computer will be used.
                   <br/><br/>
                   For more information on Google Web Fonts, please follow
                   this link: <a href="https://developers.google.com/fonts/faq" target="_blank"
                                 rel="noopener noreferrer">https://developers.google.com/fonts/faq</a> and consult
                   Google’s
                   Data Privacy Declaration under: <a href="https://policies.google.com/privacy?hl=en" target="_blank"
                                                      rel="noopener noreferrer">https://policies.google.com/privacy?hl=en</a>.
               </Typography>


               <br/>
               <Typography component="h3"></Typography>
               <br/>
               <Typography variant="body2" component="p"></Typography>


           </Layout>
        )
    }
}